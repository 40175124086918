<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="color3Text color3--text"
        v-on="on"
      >Update Points</v-btn>
    </template>
    <v-card>
      <v-card-title class="justify-center title">
        Are you sure?
      </v-card-title>
      <v-card-text>
        Points are locked when you seed the teams, so you know what they were at the time of seeding.
        By continuing you will overwrite the points with the current total. Would you like to continue?
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="success" @click.stop="doIt">yes</v-btn>
        <v-btn color="error" @click.stop="dialog = false">no</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['divisionId'],
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    doIt () {
      this.$http.get(`division/${this.divisionId}/teams/withlookup`)
        .then(t => {
          this.dialog = false
        })
    }
  }
}
</script>
